import { useLocation } from "react-router-dom";
import React, { createContext, useState, useEffect } from "react";
import Header from "../layouts/header";
import Title from "../components/title";
import SelectPlan from "../components/plans-and-services/select-plan";
import Footer from "../components/footer";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";
import { useTranslation } from "react-i18next";

export default function PlansAndServices(props) {
  const [t, i18next] = useTranslation("global");
  const language = localStorage.getItem("i18nextLng") || "es";

  let { state } = useLocation();

  useEffect(() => {
    document.title = "Planes y precios - SAX";

    // Cambiar la meta descripción
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Defina el plan ideal para su empresa, seleccione la vigencia de su plan y comience a realizar análisis eléctrico para sistemas de potencia y conocer las restricciones del sistema eléctrico."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Defina el plan ideal para su empresa, seleccione la vigencia de su plan y comience a realizar análisis eléctrico para sistemas de potencia y conocer las restricciones del sistema eléctrico.";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  const defaultPlan = {
    creation_date: "",
    payment_date: "",
    start_date: "",
    end_date: "",
    time_plan: 1,
    status: "",
    plan_value: 1090,
    total_user: 1,
    is_demo: "",
    features: [
      {
        feature_id: 5,
        name: "Usuario",
        name_english: "User",
        quantity: 1,
        unite_price: 1090,
      },
    ],
  };

  const plan = (state && state.plan) || defaultPlan;

  return (
    <ContextPlansAndServicesProvider>
      <Header {...props} />
      <Title title={t(`planPricing.planTable.title`)} />
      <SelectPlan planHome={plan} />
      <Footer />
    </ContextPlansAndServicesProvider>
  );
}
