import Header from "../layouts/header";
import Title from "../components/title";
import RegisterForm from "../components/playground/register-form";
import Footer from "../components/footer";
import React, { useEffect } from "react";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";
import { useTranslation } from "react-i18next";

function Playground(props) {
  const [t, i18next] = useTranslation("global");
  const language = localStorage.getItem("i18nextLng") || "es";
  useEffect(() => {
    document.title =
      "Descubre cómo funciona SAX de manera interactiva con nuestro Playground";

    // Cambiar la meta descripción
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Descubre cómo funciona SAX de manera interactiva con nuestro Playground. Aprende a ver las restricciones del sistema ante múltiples escenarios y contingencias!"
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Descubre cómo funciona SAX de manera interactiva con nuestro Playground. Aprende a ver las restricciones del sistema ante múltiples escenarios y contingencias!";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  return (
    <ContextPlansAndServicesProvider>
      <Header {...props} />
      <Title title={t(`playground.title`)} />
      <RegisterForm />
      <Footer />
    </ContextPlansAndServicesProvider>
  );
}

export default Playground;
