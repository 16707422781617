import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import image1 from '../../assets/item1.png';
import image2 from '../../assets/item2.png';
import image3 from '../../assets/item3.png';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const CardHover = styled(Card)({

    boxShadow: 'none',
    border: 0,
    '&:hover': {
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.3)',
        transition: 'box-shadow 0.3s ease-in-out',
    },
});

const features = [
    {
        id: 1,
        img: image1,
        title: 'home.features.time',
        description: 'home.features.timeDescription',
        alt: 'Análisis de escenarios y sus contingencias.'
    },
    {
        id: 2,
        img: image2,
        title: 'home.features.quality',
        description: 'home.features.qualityDescription',
        alt: 'SAX análisis amplio, preciso y confiable.'
    },
    {
        id: 3,
        img: image3,
        title: 'home.features.profitability',
        description: 'home.features.optimizeDescription',
        alt: 'SAX, optimiza el proceso convencional de análisis para sistemas de potencia. '
    },
]

function FeaturesSax(props) {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    return (
        <Box
            style={{
                paddingTop: '50px',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '50px',
                maxWidth: 1170
            }}
        >
            <h2
                style={{ fontSize: 32, fontWeight: 700, color: '#282828' }}
            >
                {t(`home.text.saxHelp`)}
            </h2>
            <p
                style={{
                    marginBottom: 50,
                    fontSize: 16
                }}
            >
                {t(`home.text.saxHelpDescription`)}
            </p>
            <Grid container spacing={{xs:4, md: 12}} sx={{pl: {xs: 2, md: 0}, pr: {xs: 2, md: 0}}}>
                {
                    features && features.map((feature, index) => (
                        <Grid item xs={12} md={4} key={feature.id} >
                            <CardHover variant="outlined" >
                                <CardContent>

                                    <img src={feature.img} alt={feature.alt} title={feature.alt} srcSet="" />
                                    <Typography
                                        variant="h3"
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 700,
                                            color: '#4b4b4a',
                                            marginTop: 20,
                                            marginBottom: 10
                                        }}
                                        gutterBottom
                                    >
                                        {t(feature.title)}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            mb: 1.5,
                                            color: '#888',
                                            fontSize: 14,
                                        }}
                                    >
                                        {t(feature.description)}
                                    </Typography>

                                </CardContent>
                            </CardHover>
                        </Grid>
                    ))
                }

            </Grid>
        </Box>
    );
}

export default FeaturesSax;
