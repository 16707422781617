import Header from "../layouts/header";
import Title from "../components/title";
import Footer from "../components/footer";
import ContactForm from "../components/contact/contact-form";
import { useTranslation } from "react-i18next";
import React, { createContext, useState, useEffect } from "react";

export default function Contacto(props) {
  const [t, i18next] = useTranslation("global");
  const language = localStorage.getItem("i18nextLng") || "es";
  // let { state } = useLocation();
  // const plan = state.plan;
  useEffect(() => {
    document.title = "Contáctanos - SAX";

    // Cambiar la meta descripción
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Contáctanos para recibir asistencia personalizada y descubrir cómo nuestra herramienta en la nube puede facilitar el análisis de tu red eléctrica."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Contáctanos para recibir asistencia personalizada y descubrir cómo nuestra herramienta en la nube puede facilitar el análisis de tu red eléctrica.";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  return (
    <>
      <Header {...props} />
      <Title title={t(`contact.title`)} />
      <ContactForm {...props} />
      <Footer />
    </>
  );
}
