import Header from "../layouts/header";
import Title from "../components/title";
import Footer from "../components/footer";
import TutorialsList from "../components/tutorials/tutorials-list";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

export default function Tutorials(props) {
  const [t, i18next] = useTranslation("global");
  const language = localStorage.getItem("i18nextLng") || "es";

  // let { state } = useLocation();
  // const plan = state.plan;
  useEffect(() => {
    document.title = "Aprende a usar SAX";

    // Cambiar la meta descripción
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Aprende a usar SAX, explora nuestros tutoriales detallados para robustecer/mejorar el análisis de tu red eléctrica. Encuentra guías paso a paso y consejos prácticos para estudios de seguridad, programación de mantenimientos y más."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Aprende a usar SAX, explora nuestros tutoriales detallados para robustecer/mejorar el análisis de tu red eléctrica. Encuentra guías paso a paso y consejos prácticos para estudios de seguridad, programación de mantenimientos y más.";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  return (
    <>
      <Header {...props} />
      <Title title={t(`tutorials.title`)} subtitle={t(`tutorials.subTitle`)} />
      <TutorialsList />
      <Footer />
    </>
  );
}
