import Header from "../layouts/header";
import Title from "../components/title";
import RegisterForm from "../components/register/register-form";
import Footer from "../components/footer";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

function Register(props) {
  const [t, i18next] = useTranslation("global");
  const language = localStorage.getItem("i18nextLng") || "es";
  useEffect(() => {
    document.title = "Solicitar demo - SAX";

    // Cambiar la meta descripción
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Solicita una demo gratuita de SAX y descubre cómo nuestra herramienta en la nube puede facilitar el análisis de tu red eléctrica. Aprende a identificar limitaciones, programar mantenimientos y conectar cargas de manera eficiente."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Solicita una demo gratuita de SAX y descubre cómo nuestra herramienta en la nube puede facilitar el análisis de tu red eléctrica. Aprende a identificar limitaciones, programar mantenimientos y conectar cargas de manera eficiente.";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  return (
    <ContextPlansAndServicesProvider>
      <Header {...props} />
      <Title title={t(`demo.demoRegistrationRequired`)} />
      <RegisterForm />
      <Footer />
    </ContextPlansAndServicesProvider>
  );
}

export default Register;
