import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from "react-router-dom";
import { loginrequest } from "../actions/users";
import logoSax from "../assets/logo-sax-trans.png";
import PhonoMenu from './phoneMenu';
import { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from "react-i18next";

export default function ButtonAppBar(props) {

    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    const styles = {
        display: "flex",
        marginRight: 32,
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: "0.025em",
        color: "#495258",
        alignItems: "center",
        textAlign: "center",
        textDecoration: "none",
        fontSize: 16
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChangeLanguage  = (lng) => {

        handleClose();
        localStorage.setItem("i18nextLng", lng)
        i18next.changeLanguage(lng);
        // window.location.reload()
    }

    const plan = {
        creation_date: '',
        payment_date: '',
        start_date: '',
        end_date: '',
        time_plan: 1,
        status: '',
        plan_value: 1090,
        total_user: 1,
        is_demo: '',
        features: [
            { feature_id: 5, name: 'Usuario', quantity: 1, unite_price: 1090, }
        ],
    }

    return (
        <Box
            sx={{ flexGrow: 1 }}
            style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: 1170
            }}
        >
            <AppBar position="static" style={{ backgroundColor: "white", boxShadow: 'none' }}>
                <Toolbar style={{ padding: "0px 0px 0px 0px" }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ display: 'flex', padding: 10 }}>
                        <img src={logoSax} title= "Logo de SAX" alt="Software en la nube para estudios de sistemas eléctricos de potencia" style={{ width: 160 }} />
                    </Typography>
                    <PhonoMenu />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Box style={{ display: "flex" }} >


                            <div style={{ display: "flex" }}>
                                <ul style={{ display: "flex", color: 'black', listStyle: 'none' }}>
                                    <li style={{  margin: '0.3rem 1.25rem', cursor: 'pointer', fontSize: 15 }}>
                                        <Link to={'/'} title={t(`home.mainList.home`)} style={{ textDecoration: 'none', display: "block", height: "100%" , }}>{t(`home.mainList.home`)}</Link>
                                    </li>
                                    <li style={{ margin: '0.3rem 1.25rem', cursor: 'pointer', fontSize: 15 }}>
                                        <Link to={'/planesyservicios'} title={t(`home.mainList.plans&pricing`)} state={{ plan }} style={{ textDecoration: 'none' }}>{t(`home.mainList.plans&pricing`)}</Link>
                                    </li>

                                    <li style={{ margin: '0.3rem 1.25rem', cursor: 'pointer', fontSize: 15 }}>
                                        <Link  to={'/contacto'} title={t(`home.mainList.contact`)}  style={{ textDecoration: 'none' }}>{t(`home.mainList.contact`)}</Link>
                                    </li>
                                    <li style={{ margin: '0.3rem 1.25rem', cursor: 'pointer', fontSize: 15 }}>
                                        <Link to={'/tutoriales'} title={t(`home.mainList.tutorials`)} style={{ textDecoration: 'none' }}>{t(`home.mainList.tutorials`)}</Link>
                                    </li>
                                    <div style={{ border: "1px solid #FF6A13" }}></div>
                                </ul>

                                <ul style={{
                                    display: "flex",
                                    color: 'black',
                                    listStyle: 'none',
                                    padding: 0
                                }}
                                >


                                    <li style={{
                                        margin: '0.3rem 1rem',
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                        fontSize: 15
                                    }}
                                    >
                                        <Link to={localStorage.getItem("saxToken") ? '/board' : '/solicitardemo'} title={t(`home.mainList.RequestDemo`)} style={{ textDecoration: 'none' }}>{localStorage.getItem("saxToken") ? t('home.mainList.goTo', { name: 'SAX' }) : t(`home.mainList.RequestDemo`)}</Link>
                                    </li>
                                    <li style={{
                                        margin: '0.3rem 1rem',
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                        fontSize: 15
                                    }}
                                    >
                                        <Link to={localStorage.getItem("saxToken") ? '/board' : '/playground'} title="Playground" style={{ textDecoration: 'none' }}>{localStorage.getItem("saxToken") ? "" : "Playground"}</Link>
                                    </li>

                                </ul>
                            </div>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            onClick={() => {
                                if (props.infoUser) { localStorage.removeItem('saxToken'); window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/logout` } else {

                                    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/login?language=${language}`
                                    loginrequest(language).then(response => console.log('login', response))
                                }
                            }}
                            data-gtm-id={props.infoUser ? 'logout-btn' : 'login-btn'}
                            style={{
                                borderRadius: "32px",
                                backgroundColor: "#FF6A13",
                                border: "none",
                                color: "white",
                                padding: "10px 20px",
                                textDecoration: "none",
                                margin: "4px 2px",
                                cursor: "pointer",
                                width: "184px",
                                height: "42px",
                                textTransform: "none",
                                fontSize: 16
                            }}
                            startIcon={<PersonIcon />}
                        >
                            {props.infoUser ? t(`home.mainList.LogOut`) : t(`home.mainList.logIn`)}
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <LanguageIcon style={{color: "#551A8B"}}></LanguageIcon>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => {handleChangeLanguage("es")}}>{language === "es" ? "Español" : "Spanish"}</MenuItem>
                            <MenuItem onClick={() => {handleChangeLanguage("en")}}>{language === "es" ? "English" : "English"}</MenuItem>
                        </Menu>
                    </Box>

                </Toolbar>
            </AppBar>
        </Box>
    );

}