import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import Landing from "../pages/lading";
import PlansAndServices from "../pages/plans-and-services";
import Contacto from "../pages/contact";
import ErrorPage from "../pages/error-page";
import LayoutUser from "../layouts/layout-user/index";
import LayoutUserAdmin from "../layouts/layout-user-admin/index";
import Register from "../pages/register";
import RequestPlan from "../pages/request-plan";
import LayersDatails from "../pages/user/layers-details";
import LayersDatailsAdmin from "../pages/user-admin/layers-details";
import UserList from "../pages/user/user-list";
import UserPlan from "../pages/user/user-plans";
import UserListAdmin from "../pages/user-admin/user-list";
import UpdatePlan from "../pages/user-admin/update-plan";
import EmulateCase from "../pages/user-admin/emulate-case";
import GetToken from "../app/getToken"
import jwt_decode from "jwt-decode";
import Tutorials from "../pages/tutorials";
import Playground from "../pages/playground";
import Error404 from "../pages/404";

const routesComponents =(infoUser)=>{return [
    {
        path: "/login",
        element: <GetToken />,
        errorElement: <ErrorPage />,
        token : false
    },
    {
        path: "/",
        element: <Landing infoUser={infoUser}/>,
        errorElement: <ErrorPage />,
        token : false,
    },
    {
        path: "/planesyservicios",
        element: <PlansAndServices infoUser={infoUser}/>,
        errorElement: <ErrorPage />,
        token : false
    },
    {
        path: "/contacto",
        element: <Contacto infoUser={infoUser}/>,
        errorElement: <ErrorPage />,
        token : false
    },
    {
        path: "/tutoriales",
        element: <Tutorials infoUser={infoUser}/>,
        errorElement: <ErrorPage />,
        token : false
    },
    {
        path: "/solicitardemo",
        element: <Register infoUser={infoUser}/>,
        errorElement: <ErrorPage />,
        token : false
    },
    {
        path: "/playground",
        element: <Playground infoUser={infoUser}/>,
        errorElement: <ErrorPage />,
        token : false
    },
    {
        path: "/solicitarplan",
        element: <RequestPlan infoUser={infoUser} />,
        errorElement: <ErrorPage />,
        token: false
    },
    {
        path: "/board",
        element: <LayoutUser infoUser={infoUser}/>,
        errorElement: <ErrorPage />,
        token : true,
        children: [
            {
                path: "/board/:case",
                element: <LayersDatails infoUser={infoUser}/>,
                errorElement: <ErrorPage
                //error={'board'}
                />,
            },
            {
                path: "/board/userlist",
                errorElement: <ErrorPage />,
                element: <UserList
                />,
                token : true,
            },
            {
                path: "/board/plans",
                errorElement: <ErrorPage />,
                element: <UserPlan
                />,
                token : true,
            }]

        },
        {
            path: "/admin",
            element: <LayoutUserAdmin />,
            errorElement: <ErrorPage />,
            token: true,
            children: [
                {
                    path: "/admin/:userId",
                    errorElement: <ErrorPage />,
                    element: <LayersDatailsAdmin />

                },
                {
                    path: "/admin/userlist",
                    errorElement: <ErrorPage />,
                    element: <UserListAdmin />
                },
                {
                    path: "/admin/updateplan",
                    errorElement: <ErrorPage />,
                    element: <UpdatePlan />
                },
                {
                    path: "/admin/emulatecase",
                    errorElement: <ErrorPage />,
                    element: <EmulateCase />
                },
            ],
        },
        {
            path: "/*",
            element: <Error404 infoUser={infoUser}/>,
            //errorElement: <ErrorPage />,
            token : false
        },


]}
let router =(token) => {
    const infoUser = token ? jwt_decode(token) : null;
    const permits = token ? infoUser.permits : [];
    const routes = routesComponents(infoUser)
    .filter(ele=> (ele.token && token && permits.includes(ele.path)) || !ele.token )
    .map(row=>{if (row.children) {
        let newChildren = row.children
        .filter(ele=>( ele.token && token && permits.includes(ele.path)) || !ele.token)
        return ({...row, children: newChildren})
    }
    return row
    })
    return createBrowserRouter(routes)

}


export default router;
