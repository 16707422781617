import axios from 'axios';

 const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api` 

function validateToken(response) {
   
  if ( response.status === 401 ) {
    //Token invalido, se borra el token del localStorage
    localStorage.removeItem( "saxToken" );
    window.location.href = "/?expirate=true"
  } else if (response.status === 302 ) {    
    //Token invalido, se borra el token del localStorage
    localStorage.removeItem( "saxToken" );
    window.location.href = "/?expiratedPlan=true"
  } else if (response.status === 402 ){
    localStorage.removeItem( "saxToken" );
    window.location.href = "/?expirate=true"
  };
  
  return response;
}


function request(url, options) {
  //default fetch , requestAxios
  
  return fetch(`${API_URL}${url}`, options) 
    .then(validateToken)
    .then((res, error) => {
      if (res.headers.get('Content-Type')=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'|| 
      res.headers.get('Content-Type')=='application/zip') return res
      return res.json()})
    .catch((err) => err);
}


function getOptions (method, data = null) {
  
  const options = {
    method,
    ...(data && { body: JSON.stringify(data) }),
    headers: {
      
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("saxToken")}`,
      platform: `${process.env.REACT_APP_CONFIG_SECRECT}`,
      lng: "es",
      "x-forwarded-for":localStorage.getItem("ip")
    },
  };
  return options;
}

async function requestAxios (url, options){
  const QuotesClient = axios.create({
    baseURL: `${API_URL}` ,
    ...options
  });

  return await QuotesClient[options.method](
      `/${url}`,
      //listMonitored,
      // {
      //     responseType: 'arraybuffer'
      // }
  );
}

export const GET = url => {
  const options = getOptions("GET");
  return request(url, options);
};
  
export const POST = (url, data) => {
  const options = getOptions("POST", data);
  return request(url, options);
};
  
export const DELETE = (url, data) => {
  const options = getOptions("DELETE", data);
  return request(url, options);
};
  

