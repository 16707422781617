import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { margin } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function DescirptionHome() {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    return (
        <Box
            style={{
                marginTop: '50px',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'left',
                maxWidth: 1170
            }}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'

                    }}
                    sx={{
                        textAlign: { xs: 'center', md: 'left' }
                    }}
                >
                    <h1 style={{
                        fontWeight: 700,
                        color: '#282828',
                        fontSize: 36,
                        lineHeight: 1.2,
                        marginBottom: 16
                    }}
                    >{t(`home.text.title`)}
                    </h1>
                    <Typography
                        sx={{ m: { xs: 4, md: 0 } }}
                        style={{
                            fontWeight: 400,
                            color: '#4b4b4a',
                            fontSize: 16
                        }}
                        >{t(`home.text.subTitle`)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ ml: { xs: 2, md: 0 }, mr: { xs: 2, md: 0 } }}>
                    <iframe
                        allow="autoplay; encrypted-media"
                        allowFullScreen=""
                        frameBorder="0"
                        height="315"
                        src="https://www.youtube.com/embed/1jg9NNDVlrg"
                        style={{ width: "100%" }}
                    >
                    </iframe>

                </Grid>
            </Grid>
        </Box>
    )
}