import Header from "../layouts/header";
import Title from "../components/title";
import RequestPlanForm from "../components/request-plan/request-plan-form";
import Footer from "../components/footer";
import Loader from "../components/loader";
import React, { createContext, useState, useEffect } from "react";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";
import { useTranslation } from "react-i18next";

function RequestPlan(props) {
  const [t, i18next] = useTranslation("global");
  const language = localStorage.getItem("i18nextLng") || "es";
  useEffect(() => {
    document.title = "Solicita tu plan - SAX";

    // Cambiar la meta descripción
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Diligencia la siguiente información y conoce el resumen de tu pedido para adquirir tu plan en SAX."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Diligencia la siguiente información y conoce el resumen de tu pedido para adquirir tu plan en SAX.";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  return (
    <>
      <ContextPlansAndServicesProvider>
        <Header {...props} />
        <Title title={t("requestPlan.fillInformation")} />
        <RequestPlanForm />
        <Footer />
        <Loader />
      </ContextPlansAndServicesProvider>
    </>
  );
}

export default RequestPlan;
