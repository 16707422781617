import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LogoXm from '../assets/logo-xm.png'

import ImgFacebook from '../assets/facebook-logo.png';
import ImgInstagram from '../assets/instagram-logo.png';
import ImgLinkedin from '../assets/linkedin-logo.png';
import ImgYoutube from '../assets/youtube-logo.png';
import { useTranslation } from 'react-i18next';

function Footer() {
    const [t, i18next] = useTranslation("global")
    const language = localStorage.getItem('i18nextLng') || 'es'

    return (
        <div style={{ background: '#fafafa' }}>
            <Box
                style={{
                    paddingTop: '40px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderTop: '2px solid #e4e4e4',
                    paddingBottom: '40px',
                    maxWidth: 1170
                }}
            >
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            paddingLeft: 15
                        }}
                    >
                        <p style={{ marginRight: 10 }}>{t(`footer.copyright`)}</p>
                        <img src={ImgFacebook} title='facebook' alt="logo facebook" width='25px' style={{ marginRight: 10 }} />
                        <img src={ImgInstagram} title='instagram' alt="logo instagram" width='25px' style={{ marginRight: 10 }} />
                        <img src={ImgLinkedin} title='linkedin' alt="logo linkedin" width='25px' style={{ marginRight: 10 }} />
                        <img src={ImgYoutube} title='youtube' alt="logo youtube" width='25px' />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            paddingRight: 15
                        }}
                    >
                        <img
                            src={LogoXm}
                            alt="Operador del Sistema Interconectado Nacional y administrador del mercado de energía."
                            title='Logo XM'
                            width='100px'
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Footer;